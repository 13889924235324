<template>
    <div ref="map" :class="className"></div>
</template>

<script>
import { useStores } from '../composables/useStores';
import { toRaw } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

export default {
    name: 'GoogleMap',
    props: {
        className: {
            type: String,
            default: ''
        },
        apiKey: {
            type: String,
            required: true
        },
        mapId: {
            type: String,
            required: true
        },
        markers: {
            type: Array,
            required: true
        }
    },
    setup() {
        const { formatStoreAddress, googleMapsUrl } = useStores();

        // Return the methods to make them available in the template
        return {
            formatStoreAddress,
            googleMapsUrl
        };
    },
    data() {
        return {
            mapLoader: new Loader({
                apiKey: this.apiKey,
                version: 'weekly'
            }),
            mapInstance: null,
            mapMarkers: [],
            markerCluster: null,
            advancedMarkerElement: null,
            mapBounds: null,
            mapInfoWindow: null,
            initialCenter: { lat: 56.157112, lng: 9.912824 },
            initialZoom: 5
        };
    },
    computed: {
        mapInstanceRaw() {
            return toRaw(this.mapInstance);
        },
        mapMarkersRaw() {
            return toRaw(this.mapMarkers);
        }
    },
    mounted() {
        this.mapLoader.load().then(async () => {
            const { Map, InfoWindow } = await google.maps.importLibrary('maps');
            const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

            // Initialize the map
            const map = new Map(this.$refs.map, {
                center: this.initialCenter,
                zoom: this.initialZoom,
                mapId: this.mapId
            });

            this.mapInstance = map;

            // Set info window
            this.mapInfoWindow = new InfoWindow();

            // Store AdvancedMarkerElement and PinElement in data
            this.advancedMarkerElement = AdvancedMarkerElement;
            this.pinElement = PinElement;

            // Create markers initially
            this.createMarkers();

            // Watch for changes in the "markers" prop
            this.$watch('markers', this.createMarkers, { deep: true });
        });
    },
    methods: {
        createMarkers() {
            const AdvancedMarkerElement = this.advancedMarkerElement;
            const PinElement = this.pinElement;

            // Clear existing markers from map and clusterer
            if (this.mapMarkers.length) {
                this.mapMarkers.forEach(marker => marker.setMap(null));
                this.mapMarkers = [];
            }
            if (this.markerCluster) {
                this.markerCluster.clearMarkers();
            }

            // Reset map bounds
            this.mapBounds = new google.maps.LatLngBounds();

            if (this.markers && this.markers.length > 0) {
                const markers = this.markers.map(markerData => {
                    const glyphImg = document.createElement('img');
                    glyphImg.src = '/assets/svg/logo-minified.svg';

                    const markerPosition = { lat: markerData.position.lat, lng: markerData.position.lng };

                    if (!markerData.position.lat || !markerData.position.lng) {
                        console.log('Missing position for', markerData);
                        return null;
                    }

                    const pin = new PinElement({
                        background: '#222020',
                        borderColor: '#72757B',
                        glyph: glyphImg
                    });

                    const marker = new AdvancedMarkerElement({
                        position: markerPosition,
                        content: pin.element,
                        title: markerData.shopName
                    });

                    const infoWindow = `<div class="store-locator__item">
                                            <strong class="store-locator__item-title">${markerData.shopName}</strong>
                                            <span class="store-locator__item-address">${this.formatStoreAddress(markerData)}</span>
                                            <a href="${this.googleMapsUrl(markerData)}" target="_blank" title="${this.$t('Dictionary.StoreLocator.GetDirections')}" class="store-locator__item-link">${this.$t('Dictionary.StoreLocator.GetDirections')}</a>
                                        </div>`;

                    marker.addListener('click', () => {
                        this.mapInfoWindow.setContent(infoWindow);
                        this.mapInfoWindow.open(marker.map, marker);
                    });

                    this.mapBounds.extend(marker.position);

                    return marker;
                }).filter(marker => marker !== null);

                this.mapMarkers = markers;

                // Initialize MarkerClusterer with the custom renderer
                this.markerCluster = new MarkerClusterer({
                    map: this.mapInstanceRaw,
                    markers,
                    renderer: this.clustererRenderer
                });

                // Fit map to bounds
                this.mapInstanceRaw.fitBounds(this.mapBounds);

            } else {
                this.mapInstance.setCenter(this.initialCenter);
                this.mapInstance.setZoom(this.initialZoom);
            }
        }
    }
};
</script>
